






































import { defineComponent, ref, toRefs } from "@vue/composition-api";
import bgImg from "@/components/bgImg/index.vue";
import Menu from "@/components/menu/index.vue";
import offcanvas from "@/components/offcanvas/index.vue";
import InfoWarp from "@/components/infowarp/index.vue";
import HtmlInfo from "@/components/content/html.vue";
import { useList, getListToTypeFn, useDom } from "@/use";
export default defineComponent({
  components: {
    bgImg,
    Menu,
    offcanvas,
    InfoWarp,
    HtmlInfo,
  },
  setup() {
    const src = ref("custom-service/custom-service.jpeg");
    const title = "解决方案";
    const {
      query,
      Mdata,
      contentData,
      nodeClick: nCfn,
      meunActive,
    } = getListToTypeFn("solution", "id");
    const { offcanvas, showOffcanvasMenu, skipPage } = useDom();
    // 点击目录
    const nodeClick = (val: any) => {
      nCfn(val);
      if (query.type) {
        skipPage("/solution", {
          type: "list",
          menuactive: val.id,
        });
      }
    };

    return {
      src,
      title,
      nodeClick,
      Mdata,
      ...toRefs(contentData),
      ...toRefs(meunActive),
      offcanvas,
      showOffcanvasMenu,
      meunActive,
    };
  },
});
